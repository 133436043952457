import { ethers } from 'ethers'
import { Buffer } from 'buffer'

export const hasElemnt = (node: HTMLElement | ChildNode, target: EventTarget) => {
  if (node === target) {
    return true
  }
  if (!node.hasChildNodes()) {
    return false
  }
  for (let i = 0; i < node.childNodes.length; i += 1) {
    if (hasElemnt(node.childNodes[i], target)) {
      return true
    }
  }
  return false
}

export const signAccount = async (account: any, ethereum: any) => {
  try {
    if (!account) {
      throw new Error('Account is null, please check metamask')
    }
    const msg = Buffer.from(account, 'utf8').toString('hex')
    const sign = await ethereum.request({
      method: 'personal_sign',
      params: [msg, account]
    })
    return sign
  } catch(err) {
    console.error(err)
  }
}

export function isSameAddress(a: string, b: string) {
  return ethers.utils.getAddress(a) === ethers.utils.getAddress(b)
}


export function formatBalance(balance: string, decimals:number = 5) {
  const [a, b] = balance.split('.')
  if (Number(b) === 0) {
    return a
  }
  if (decimals === -1) {
    return `${a}.${b}`
  }

  let t = ''
  for (let i = 0; i < b.length; i += 1) {
    if (i > decimals) {
      break
    }
    t += b[i]
  }
  return Number(`${a}.${t}`)
}

