import React, { useState, useEffect, useRef } from "react"
import { useOnClickOutside } from 'usehooks-ts'
import { useMetaMask } from 'metamask-react'
import { networks, networkRpc, IdNetworkInfo } from "../../constant"

import { hasElemnt } from '../../utils'

import "./Networks.css"


interface ProviderProps {
}

function Networks({ }: ProviderProps) {
  const _networks = Array.from(IdNetworkInfo.values())
  const {status, connect, account, chainId, ethereum} = useMetaMask()
  const [_open, setOpen] = useState(false)
  const el = useRef<HTMLDivElement>(null)
  const currentNetwork = _networks.find(network => network.id === chainId)

  const handleClickOutside = () => {
    setOpen(false)
  }

  const handleClickInside = () => {
    setOpen(true)
  }

  useOnClickOutside(el, handleClickOutside)

  const _onClick = async (chainId:string) => {
    try {
      await ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId }]
      })
    } catch (error) {
      if (error.code === 4902) {
        try {
          await ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [networkRpc.get(chainId)],
          });

        } catch (addError) {
          console.error(addError);
        }
      }
    } finally {
      setOpen(false)
    }
  }

  if (!chainId) {
    return null;
  }

  return (
    <div className="Networks" ref={el}>
      <div className="NetworkName" onClick={() => { setOpen(!_open) }}>
        {
          currentNetwork ? (
            <>
              <img src={currentNetwork.logoURI} style={{ width: 24, marginRight: '8px' }} />
              {currentNetwork.displayName}
            </>
          ) : "Wrong Network"
        }
      </div>
      <ul style={{ display: _open ? "block" : "none" }}>
        {
          _networks.map(network => (
              <li key={network.id} onClick={() => _onClick(network.id)}>
                <img src={network.logoURI} style={{ width: 24, marginRight: '8px' }} />
                {network.displayName}
              </li>
            ))
        }
      </ul>
    </div>
  )
}

export default Networks
