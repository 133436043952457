import React, { useState } from 'react'
import './Checkbox.css'
import rightImage from '../../assets/right.svg'

interface ProviderProps {
  onChange?: (checked:boolean) => void;
}

function Checkbox({ onChange = () => {} }: ProviderProps) {
  const [checked, setChecked] = useState(false)
  const _onClick = () => {
    setChecked(!checked)
    onChange(!checked)
  }

  return (
    <div
      tabIndex={0}
      onClick={_onClick}
      onKeyUp={(e) => {
        console.log(e.key)
        if (e.key === 'Enter') {
          _onClick()
        }
      }}
      className={checked ? "Checkbox checked" : "Checkbox"}
    >
      { checked ? <img className="Checkbox-image" src={rightImage} alt="checked" /> : null }
    </div>
  )
}

export default Checkbox
