import React, { useState, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import Button from '../../components/Button'

import rightArrow from '../../assets/right-arrow.svg'

import './Landing.css'

function Landing() {
  const navigate = useNavigate()
  const [_focus, setFocus] = useState(false)
  const input = useRef<HTMLInputElement>(null)

  return (
    <div className="Landing">
      <div className="Landing-container">
        <div className="Landing-input-container">
          {
            _focus || (input.current && input.current.value) ? (
              null
            ) : (
              <span className="Landing-input-placeholder">
                Input your deal id
              </span>
            )
          }
          <input ref={input} className="Landing-input"
            onFocus={() => { setFocus(true) }}
            onBlur={() => { setFocus(false) }}
            onKeyPress={(event:React.KeyboardEvent<HTMLInputElement>) => {
              if (event.key === 'Enter' && input && input.current && input.current.value) {
                navigate(`/lock/${input.current.value}`)
              }
              if (isNaN(Number(event.key))) {
                event.preventDefault()
              }
            }}
          />
          <img className={`Landing-arrow ${_focus || (input.current && input.current.value)
            ? 'Landing-arrow-show' : ''}`} src={rightArrow} alt="arrow"
               onClick={(event) => {
                event.preventDefault()
                if (input && input.current && input.current.value) {
                  navigate(`/lock/${input.current.value}`)
                }
          }}/>
        </div>
        <div style={{ margin: '16px 0' }}> Or </div>
        <div>
          <Button
            type="w210"
            onClick={() => {
              setTimeout(() => {
                navigate('/create')
              }, 0)
          }}>
            Make a Deal
          </Button>
        </div>
      </div>
    </div>
  )
}

export default Landing
