import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useMetaMask } from 'metamask-react'
import { ethers } from 'ethers'
import { Buffer } from 'buffer'
import api from '../../api'
import { Stuff } from '../../types'
import { networkCoins, coinNames } from '../../constant'
import { signAccount } from '../../utils'
import { createUpdateData } from '../../fetch'
import Input from '../../components/Input'
import Button from '../../components/Button'
import Card from '../../components/Card'
import CryptoSelector from '../../components/CryptoSelector'
import StuffForm from '../../components/StuffForm'

import './Trade.css'

function Trade() {
  const { account, ethereum } = useMetaMask()
  const navigate = useNavigate()

  return (
    <Card classes="Card-wide">
      <div className='Trade'>
        <StuffForm
          rowClass='Trade-row'
          onSave={async (data:any) => {
            const sign = await signAccount(account, ethereum)
            await createUpdateData('POST', { ...data, account, sign, 'owner_address': account },
                                   api.FETCH_STUFF,
                                   (_data:Stuff) => {
                                     setTimeout(() => {
                                       navigate(`/detail/${_data.id}`)
                                     }, 0)
                                   })
          }}
        />
      </div>
    </Card>
  )
}

export default Trade
