import React, { useState } from 'react'
import './Dialog.css'

import closeSvg from '../../assets/close.svg'

interface ProviderProps {
  children?: React.ReactNode,
  title?: string,
  width?: number,
  height?: number,
  _closed?: boolean,
  onClose?: () => void
}

function Dialog({ title = '', width = 300, height = 400, children = null, onClose = () => {} }: ProviderProps) {
  const [ pending, setPending ] = useState(false)
  const [ disabled, setDisabled ] = useState(false)

  const _onClose = async () => {
    onClose()
  }

  return (
    <div className="dialog" style={{ width, height }}>
      <div className="dialog-close-button" onClick={_onClose}>
        <img src={closeSvg} alt="close button" />
      </div>
      <div className="dialog-head">
        <div className="dialog-title">
          {title ? title : ''}
        </div>
      </div>
      <div className="dialog-content">
        {children}
      </div>
    </div>
  )
}

export default Dialog
