const protocol:string = process.env.NODE_ENV === 'development' ?
  'http' : 'https'
const host:string = process.env.NODE_ENV === 'development' ?
  `${protocol}://127.0.0.1:8000` : `${protocol}://api.crydeal.com`
const urlPath:any = {
  FETCH_STUFF: '/api/stuffs/',
  FETCH_STUFF_DETAIL: (id:string) => `/api/stuff/${id}`,
}

for (let key in urlPath) {
  if (urlPath[key] instanceof Function) {
    urlPath[`${key}_`] = urlPath[key]
    urlPath[key] = (...args:any[]) => `${host}${urlPath[`${key}_`](...args)}`
  } else {
    urlPath[key] = `${host}${urlPath[key]}`
  }
}

export default urlPath
