import React from 'react'
import './Card.css'

interface ProviderProps {
  children: React.ReactNode
  classes?: string,
  style?: object,
}

function Card({ children, classes = "", style = {} }: ProviderProps) {
  return (
    <div className={classes ? `Card ${classes}` : 'Card'} style={style}>
      {children}
    </div>
  )
}

export default Card
