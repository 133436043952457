import React, { Suspense } from 'react'
import { Route, Routes, NavLink, useLocation, useNavigate, useMatch } from 'react-router-dom'
import logo from '../assets/Light-Crydeal.png'
import bg from './Caduceus_large.jpeg'
import './App.css'

import Create from './Create'
import Lock from './Lock'
import Landing from './Landing'
import Marketplace from './Marketplace'
import Detail from './Detail'
import Trade from './Trade'

import ConnectWallet from '../components/ConnectWallet'
import Button from '../components/Button'

function App() {
  const navigate = useNavigate()
  const matchMarketplace = useMatch('/marketplace')

  return (
    <div className='App'>
      <nav className='App-nav'>
        <div className='App-nav-left'>
          <a className='App-nav-left-logo' href='/#/create' style={{ textDecoration: 'none' }}>
            <img className='App-logo' src={logo} alt='logo' />
          </a>
          <ul className='App-nav-left-menu'>
            {
              // <li><NavLink to='/create'> Deal </NavLink></li>
              // <li><NavLink to='/marketplace'> Marketplace </NavLink></li>
            }
          </ul>
        </div>
        <div className='App-nav-right'>
          <Button
            style={Boolean(matchMarketplace) ? {opacity: 1} : {opacity: 0}}
            disabled={!Boolean(matchMarketplace)}
            type='w210'
            onClick={() => {
              setTimeout(() => {
                navigate('/trade')
              }, 0)
            }}>
            Sell & Buy
          </Button>
          <ConnectWallet />
        </div>
      </nav>
      <main className='App-main'>
        <Suspense fallback={'...'}>
          <Routes>
            <Route path='' element={<Landing />} />
            <Route path='landing' element={<Landing />} />
            <Route path='create' element={<Create />} />
            <Route path='lock'>
              <Route path=':dealId' element={<Lock />} />
            </Route>
            <Route path='marketplace' element={<Marketplace />} />
            <Route path='detail/:id' element={<Detail />} />
            <Route path='trade' element={<Trade />} />
          </Routes>
        </Suspense>
      </main>
    </div>
  )
}

export default App
