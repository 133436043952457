import React, { useState, useEffect } from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'
import { useParams } from 'react-router-dom'
import { useMetaMask } from 'metamask-react'
import { ethers } from 'ethers'

import { formatBalance } from '../../utils'
import { ZERO_ADDRESS, networkContracts, IdNetworkInfo } from '../../constant'
import { networkTokenList } from '../../tokens'
import { Network, Token } from '../../types'
import crydealAbi from '../../assets/abi/Crydeal.json'
import erc20Abi from '../../assets/abi/IERC20.json'

import Card from '../../components/Card'
import Button from '../../components/Button'
import Address from '../../components/Address'
import ConnectWallet from '../../components/ConnectWallet'
import CryptoLogo from '../../components/CryptoLogo'
import NetworkMark from '../../components/NetworkMark'
import DealIdInput from '../../components/DealIdInput'

import rightArrow from '../../assets/right-arrow.svg'
import copyLogo from '../../assets/copy.svg'

import './Lock.css'

const ZERO = ethers.BigNumber.from('0')

const shorthandAddress = (address:string, count:number = 4) => {
  let start
  if (!address.startsWith('0x')) {
    address = `0x${address}`
  }
  return `${address.substring(0, count + 2)}....${address.substring(address.length-count, address.length)}`
}

const trustWalletLogo = (platform:string, address:string) => {
  return `https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/${platform}/assets/${address}/logo.png`
}

function isSameAddress(a: string, b: string) {
  return ethers.utils.getAddress(a) === ethers.utils.getAddress(b)
}

interface ProviderProps {
  dealId: string | undefined
}

function DealIdCopy ({ dealId }:ProviderProps) {
  const [_copied, setCopied] = useState(false)
  const [_mouseOver, setMouseOver] = useState(false)

  if (!dealId) {
    return null
  }

  return (
    <CopyToClipboard text={dealId} onCopy={() => { setCopied(true) }}>
      <div className='Lock-id-copy'
        onMouseOver={() => { setMouseOver(true) }}
        onMouseLeave={() => {
          setMouseOver(false)
          setCopied(false)
        }}>
        <span className='Lock-id-text'> # {dealId} </span>
        <span className='Lock-id-copy-icon'>
          { _mouseOver ? (
              _copied ? 'Copied' :
                        <img style={{ width: 16 }} src={copyLogo} alt='Copy' />
            ) : null
          }
        </span>
      </div>
    </CopyToClipboard>
  )
}

interface LockButtonProp {
  is2: boolean
  isApprove: boolean
  dealId: string | undefined
  account: string
  isLocked: boolean
  isOtherSideLocked: boolean
  lockAddress: string
  otherSideAddress: string
  Approve: () => void
  amountToLock: ethers.BigNumber
  balance: ethers.BigNumber
  CRYDEAL: string
  initialData: () => void
  ethereum: any
}

const defaultProp = {
  is2: false,
  isApprove: false,
  dealId: '',
  account: '',
  isLocked: false,
  isOtherSideLocked: false,
  lockAddress: '',
  otherSideAddress: '',
  Approve: () => { },
  amountToLock: ZERO,
  balance: ZERO,
  CRYDEAL: '',
  initialData: () => { },
  ethereum: null
}

function LockButton ({ is2, isApprove, dealId, account, isLocked, isOtherSideLocked, lockAddress, otherSideAddress,
                     Approve, amountToLock, balance, CRYDEAL, initialData, ethereum }: LockButtonProp = defaultProp) {
  const [_pending, setPending] = useState(false)

  const getCrydealContract = () => {
    const provider = new ethers.providers.Web3Provider(ethereum)
    const signer = provider.getSigner()
    const crydealContract = new ethers.Contract(CRYDEAL, crydealAbi, signer)
    return crydealContract
  }

  if (!ethereum) return null
  if (!account) return null
  if (!dealId) return null

  if (!isApprove && isSameAddress(lockAddress, ZERO_ADDRESS)) {
    return (
      <Button disabled={_pending} type='w120 h32' onClick={async () => {
          try {
            setPending(true)
            await Approve()
          } catch(err) {
            console.error(err)
          } finally {
            setPending(false)
          }
        }}>
        { _pending ? 'Pending...' : 'Approve' }
      </Button>
    )
  }

  if (!isLocked && isSameAddress(lockAddress, ZERO_ADDRESS)) {
    return (
      <Button disabled={amountToLock.gt(balance) || _pending} type='w120 h32' onClick={async () => {
        try {
          setPending(true)
          const crydealContract = getCrydealContract()
          if (!crydealContract) {
            throw new Error('Not found contract')
          }
          const tx = is2 ? await crydealContract.lockTo(dealId) : await crydealContract.lockFrom(dealId)
          const receipt = await tx.wait()

          if (receipt.status === 1) {
            await initialData()
          } else {
            // todo error
          }
        } catch(err) {
          console.error(err)
        } finally {
          setPending(false)
        }
      }}>
        { _pending ? 'Pending...' : 'Lock' }
      </Button>
    )
  }

  if (!isOtherSideLocked && isSameAddress(otherSideAddress, ZERO_ADDRESS) && isSameAddress(account, lockAddress)) {
    return (
      <Button disabled={_pending} type='w120 h32' onClick={async () => {
        try {
          setPending(true)
          const crydealContract = getCrydealContract()
          const tx = is2 ? await crydealContract.cancelTo(dealId) : await crydealContract.cancelFrom(dealId)
          const receipt = await tx.wait()

          if (receipt.status === 1) {
            await initialData()
          } else {
            // todo error
          }
        } catch(err) {
          console.error(err)
        } finally {
          setPending(false)
        }
      }}>
        { _pending ? 'Pending...' : 'Cancel' }
      </Button>
    )
  }

  if (isLocked && isSameAddress(account, lockAddress)) {
    return (
      <div style={{ display: 'flex' }}>
        <div className='Lock-text'>
          Locked
        </div>
        <Button disabled={_pending} type='w120 h32' onClick={async () => {
          try {
            setPending(true)
            const crydealContract = getCrydealContract()
            const tx = is2 ? await crydealContract.unlockTo(dealId) : await crydealContract.unlockFrom(dealId)
            const receipt = await tx.wait()

            if (receipt.status === 1) {
              await initialData()
            } else {
              // todo error
            }
          } catch(err) {
            console.error(err)
          } finally {
            setPending(false)
          }
        }}>
          { _pending ? 'Pending...' : 'Unlock' }
        </Button>
      </div>
    )
  }

  return (
    <div className='Lock-text'> { isLocked ? 'Locked' : 'Unlocked' } </div>
  )
}

function Lock() {
  let { account, ethereum, status, chainId } = useMetaMask()
  const { dealId } = useParams()

  account = account ? ethers.utils.getAddress(account) : account;

  const [_network, setNetwork] = useState<Network>()
  const [_amountToLock, setAmountToLock] = useState(ethers.BigNumber.from('0'))
  const [_amountToPay, setAmountToPay] = useState(ethers.BigNumber.from('0'))
  const [_token, setToken] = useState<Token>()
  const [_to, setTo] = useState('')
  const [_from, setFrom] = useState('')
  const [_lockTo, setLockTo] = useState(false)
  const [_lockFrom, setLockFrom] = useState(false)
  const [_isApprove, setIsApprove] = useState(false)
  const [_pending, setPending] = useState(true)
  const [_exists, setExists] = useState(false)
  const [_isEnd, setIsEnd] = useState(false)
  const [_lastEndTxHash, setLastEndTxHash] = useState('')
  const [_tokenBalance, setTokenBalance] = useState('')
  const [_symbol, setSymbol] = useState('')
  const [_decimals, setDecimal] = useState('')
  const [_balance, setBalance] = useState(ethers.BigNumber.from('0'))
  const [_logs, setLogs] = useState<any[]>()

  const CRYDEAL = networkContracts.get(chainId || '') || ''

  const initialData = async () => {
    if (status !== 'connected') {
      return
    }
    if (!account) {
      return
    }

    try {
      !_pending && setPending(true)
      const provider = new ethers.providers.Web3Provider(ethereum)
      const signer = provider.getSigner(account)
      const crydealContract = new ethers.Contract(CRYDEAL, crydealAbi, signer)
      const [amount, payment, token, to, from, lockTo, lockFrom] =
        await crydealContract.deals(dealId)
      const tokenContract = new ethers.Contract(token, erc20Abi, provider)

      if (amount.eq(0)) {
        throw new Error(`Not found lock #${dealId}`)
      }

      const [_symbol, _decimals, _balance] = await Promise.all([
          tokenContract.symbol(),
          tokenContract.decimals(),
          tokenContract.balanceOf(account),
        ])

      const _tokens = networkTokenList.get(chainId || '') || []
      const _token = _tokens.find(t => isSameAddress(t.address, token))

      setSymbol(_symbol)
      setDecimal(_decimals)
      setBalance(_balance)
      setAmountToLock(amount)
      setAmountToPay(payment)
      setToken(_token)
      setTo(to)
      setFrom(from)
      setLockTo(lockTo)
      setLockFrom(lockFrom)
      setExists(amount.gt(0))
    } catch (err) {
      setExists(false)
      if (process.env.NODE_ENV === 'development') {
        console.error(err)
      }
    } finally {
      setPending(false)
    }
  }

  const checkApprove = async () => {
    if (!account) {
      return
    }
    if (!_token) {
      return
    }
    const provider = new ethers.providers.Web3Provider(ethereum)
    const signer = provider.getSigner(account)
    const coinContract = new ethers.Contract(_token.address, erc20Abi, signer)
    const allowance = await coinContract.allowance(account, CRYDEAL)
    if (allowance.isZero()) {
      setIsApprove(false)
    } else {
      setIsApprove(true)
    }
  }

  const getCrydealContract = () => {
    const provider = new ethers.providers.Web3Provider(ethereum)
    const signer = provider.getSigner()
    const crydealContract = new ethers.Contract(CRYDEAL,
      crydealAbi, signer)
    return crydealContract
  }

  async function Approve() {
    try {
      if (!account) {
        return
      }
      if (!_token) {
        return
      }
      const provider = new ethers.providers.Web3Provider(ethereum)
      const signer = provider.getSigner(account)
      const coinContract = new ethers.Contract(_token.address, erc20Abi, signer)
      await coinContract.approve(CRYDEAL,
        '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff')
      await checkApprove()
    } catch(err) {
      console.error(err)
    }
  }

  async function LockFinishListener(id: ethers.BigNumber, event: ethers.Event) {
    const provider = new ethers.providers.Web3Provider(ethereum)
    if(id.toString() === dealId && event.blockNumber === await provider.getBlockNumber()) {
      setIsEnd(true)
      setLastEndTxHash(event.transactionHash)
    }
  }

  async function getContractHistory() {
    const currentNetwork = IdNetworkInfo.get(chainId || '') || ''

    if (!currentNetwork) {
      return
    }

    if (!dealId) {
      return
    }

    const provider = new ethers.providers.Web3Provider(ethereum)
    const currentBlock = await provider.getBlockNumber()
    const fromBlock = currentBlock - currentNetwork.dailyBlock * 7
    const filter = {
      address: CRYDEAL,
      fromBlock,
      toBlock: 'latest',
    }
    const logs = (await provider.getLogs(filter)).filter(log => {
      return ethers.BigNumber.from(log.data).eq(dealId)
    })
    const txnHash = Array.from(new Set(logs.map(log => log.transactionHash)))
    const txns = await Promise.all(txnHash.map(async hash => {
      const txn = await provider.getTransaction(hash)
      return txn
    }))
    const iface = new ethers.utils.Interface(crydealAbi)
    const _logs = txns.map(txn => {
      const parsed = iface.parseTransaction({ data: txn.data, value: txn.value })
      return { txn: txn.hash, from: txn.from, name: parsed.name }
    })

    setLogs(_logs.reverse())
  }

  function listenEndEvent() {
    try {
      if (!account) {
        return
      }
      const contract = getCrydealContract()
      const listeners = contract.listeners('Finish')
      if (!listeners.includes(LockFinishListener)) {
        contract.on('Finish', LockFinishListener)
      }
    } catch(err) {
      throw err
    }
  }

  function unlistenEndEvent() {
    try {
      if (!account) {
        return
      }
      const contract = getCrydealContract()
      contract.off('Finish', LockFinishListener)
    } catch(err) {
      throw err
    }
  }

  useEffect(() => {
    if (!chainId) {
      return
    }
    const network = IdNetworkInfo.get(chainId)
    if (!network) {
      return
    }
    setNetwork(network)
  }, [chainId])

  useEffect(() => {
    if (status !== 'connected') {
      return
    }
    listenEndEvent()
    return () => {
      unlistenEndEvent()
    }
  }, [status, dealId, chainId])

  useEffect(() => {
    checkApprove().catch(console.error)
  }, [account, _token])

  useEffect(() => {
    if (status !== 'connected') {
      return
    }
    initialData().finally()
    getContractHistory().finally()
  }, [chainId, dealId, account])

  if (!account) {
    return (
      <div className='Lock'>
        <div className='Lock-container'>
          Please connect wallet
        </div>
      </div>
    )
  }

  if (_pending) {
    return (
      <div className='Lock'>
        <div className='Lock-container'>
          loading...
        </div>
      </div>
    )
  }

  if (!_exists) {
    return (
      <div className='Lock'>
        <div className='Lock-notfound'>
          <div style={{ display: 'flex', marginBottom: 16 }}>
            Not Found Deal #{dealId}&nbsp; <NetworkMark />
          </div>
          <div> <DealIdInput /> </div>
        </div>
      </div>
    )
  }

  return (
    <div className='Lock'>
      <div className='Lock-header'>
      </div>
      <div className='Lock-container'>
        <Card>
          <div className='Lock-dealId-input'>
            <DealIdInput />
          </div>
          <div className='Lock-card-header'>
            <div className='Lock-card-header-left Lock-card-title'>
              <DealIdCopy dealId={dealId} />
            </div>
            <div className='Lock-list Lock-card-header-right'>
            </div>
          </div>
          <div className='Lock-row'>
            <div className='Lock-col'>
              <div className='Lock-amount-left'>Lock</div>
              <div className='Lock-amount-right'>
                <div className='Lock-bolder'>
                  <span className='number' style={{ marginRight: 8 }}>
                    {formatBalance(ethers.utils.formatUnits(_amountToLock, _decimals))}
                  </span>
                  <CryptoLogo token={_token} />
                  <span style={{ marginLeft: 4 }}>
                    {_symbol}
                  </span>
                </div>
              </div>
            </div>
            <div className='Lock-balance'>
              {
                // <div style={{ marginRight: 4 }}> Balance: </div>
              }
              <div> {formatBalance(ethers.utils.formatUnits(_balance, _decimals))} </div>
            </div>
          </div>
          <div className='Lock-row Lock-address'>
            <div className='Lock-col'>
              <span style={{ fontWeight: 600 }}>From</span>
              <small style={{ fontSize: '12px' }} title={_from}>
                {
                  _from === ZERO_ADDRESS ? '' : <Address address={_from} count={10} />
                }
              </small>
            </div>
            <div className='Lock-col'>
              <LockButton
                is2={false}
                isApprove={_isApprove}
                dealId={dealId}
                account={account}
                isLocked={_lockFrom}
                isOtherSideLocked={_lockTo}
                otherSideAddress={_to}
                lockAddress={_from}
                Approve={Approve}
                amountToLock={_amountToLock}
                balance={_balance}
                CRYDEAL={CRYDEAL}
                initialData={initialData}
                ethereum={ethereum}
              />
            </div>
          </div>
          <div className='Lock-row Lock-transfer'>
            <div className='Lock-col'>
              <small> Payment </small>
              <span className='Lock-transfer-span Lock-bolder number'>
                {formatBalance(ethers.utils.formatUnits(_amountToPay, _decimals))}
              </span>
              <img className='Lock-down-arrow' src={rightArrow} alt='down arrow' />
            </div>
          </div>
          <div className='Lock-row Lock-address'>
            <div className='Lock-col'>
              <span style={{ fontWeight: 600 }}>To</span>
              <small style={{ fontSize: '12px' }} title={_to}>
                {
                  _to === ZERO_ADDRESS ? '' : <Address address={_to} count={10} />
                }
              </small>
            </div>
            <div className='Lock-col'>
              <LockButton
                is2={true}
                isApprove={_isApprove}
                dealId={dealId}
                account={account}
                isLocked={_lockTo}
                isOtherSideLocked={_lockFrom}
                otherSideAddress={_from}
                lockAddress={_to}
                Approve={Approve}
                amountToLock={_amountToLock}
                balance={_balance}
                CRYDEAL={CRYDEAL}
                initialData={initialData}
                ethereum={ethereum}
              />
            </div>
          </div>
          {
            _isEnd ? (
              <div className='Lock-end'>
                <p> This deal has ended </p>
                <Button onClick={() => {
                  setIsEnd(false)
                }}>
                  Continue
                </Button>
              </div>
            ) : null
          }
          <div style={{ textAlign: 'right' }}>
            <NetworkMark style={{ fontSize: 12 }} link={false} />
          </div>
          <div className='Lock-logs'>
            <div className='Lock-logs-head'> 7D History </div>
            <div className='Lock-logs-list'>
              {
                _logs ? _logs.map((log, index) => {
                  return (
                    <div key={index} className='Lock-log'>
                      <a className='Lock-log-txn' href={_network ? `${_network.explorer}/tx/${log.txn}` : ''} target='_blank'>
                        {log.txn.substring(0, 24)}...
                      </a>
                      <div className='Lock-log-action'>
                        <a className='Lock-log-addr' href={_network ? `${_network.explorer}/address/${log.from}` : ''} target='_blank'>
                          {log.from.substring(0, 10)}...{log.from.substring(log.from.length - 6, log.from.length)}
                        </a>
                        <div className='Lock-log-name'> {log.name} </div>
                      </div>
                    </div>
                  )
                }) : null
              }
            </div>
          </div>
        </Card>
      </div>
      <ol className='Lock-tips'>
        <li>Do not unlock your funds until the deal is completed.</li>
        <li>You have the option to cancel the deal before both parties have locked their funds. </li>
        <li>If you have locked your funds with a payment and changed your mind, you can create another deal to resolve the issue. </li>
        <li>Communication, privacy, and funds are all important.</li>
        <li>Do on your own risk.</li>
      </ol>
    </div>
  )
}

export default Lock
