import React, { useState } from "react"
import Blockies from 'react-blockies'
import { useMetaMask } from 'metamask-react'
import { coinNames, networks } from '../../constant'
import { Token } from '../../types'

interface ProviderProps {
  token?:Token | null
  diameter?: number,
}

function CryptoLogo ({ token, diameter = 24 }: ProviderProps) {
  const [notFound, setNotFound] = useState(false)

  if (!token) return null

  return (
    notFound ? (
      <Blockies
        seed={token.address}
        size={diameter}
        scale={3}
        color="#fff"
        bgColor="#000"
      />
      // <Jazzicon
      //   diameter={diameter}
      //   seed={jsNumberForAddress(token.address)}
      // />
    ) : (
      <img className="CryptoSelector-selector-img"
        src={token.logoURI}
        alt={token.name}
        onError={() => {
          setNotFound(true)
        }}
      />
    )
  )
}

export default CryptoLogo
