import { createSlice } from '@reduxjs/toolkit'
import { Stuff } from '../../types'

export interface MarketplaceState {
  readonly isEnd: boolean
  readonly page: number
  readonly pageSize: number
  readonly stuffs: Stuff[]
}

const initialState: MarketplaceState = {
  isEnd: false,
  page: 1,
  pageSize: 50,
  stuffs: [],
}

const marketplaceSlice = createSlice({
  name: 'marketplace',
  initialState,
  reducers: {
    updateIsEnd(state, action) {
      const { isEnd } = action.payload
      state.isEnd = isEnd
    },
    updatePage(state, action) {
      const { page } = action.payload
      state.page = page
    },
    updatePageSize(state, action) {
      const { pageSize } = action.payload
      state.pageSize = pageSize
    },
    appendStuffs(state, action) {
      const { stuffs } = action.payload
      state.stuffs = [...state.stuffs, ...stuffs]
    },
    updateStuffs(state, action) {
      const { stuffs } = action.payload
      state.stuffs = stuffs
    },
  }
})

export const {
  updateIsEnd,
  updatePage,
  updatePageSize,
  appendStuffs,
  updateStuffs,
} = marketplaceSlice.actions

export default marketplaceSlice.reducer
