import React, { useState } from 'react'
import { useMetaMask } from 'metamask-react'
import CryptoSelector from '../CryptoSelector'
import Button from '../Button'
import Input from '../Input'
import NetworkSelector from '../NetworkSelector'
import Select from '../Select'
import ConnectWallet from '../ConnectWallet'
import { Stuff } from '../../types'

import { IdNetworkInfo, EthereumNetwork } from '../../constant'
import { networkTokenList } from '../../tokens'

interface Props {
  rowClass:string
  onSave:Function
  stuff?:Stuff
  buttonText?:string
}

const _defaultStuff:Stuff = {
  id: 0,
  title: '',
  stuffType: '',
  ownerAddress: '',
  price: 0,
  coin: 'usdt',
  chain: '',
  lockAmount: 0,
  imageUrl: '',
  videoUrl: '',
  description: '',
  closed: false,
}

function StuffFrom({ rowClass, stuff = _defaultStuff, onSave, buttonText = 'Save' }:Props) {
  const { chainId, status } = useMetaMask()
  const [title, setTitle] = useState(stuff.title)
  const [stuffType, setStuffType] = useState(stuff.stuffType)
  const [price, setPrice] = useState(stuff.price)
  const [coin, setCoin] = useState(stuff.coin)
  const [chain, setChain] = useState(stuff.chain)
  const [lockAmount, setLockAmount] = useState(stuff.lockAmount)
  const [imageUrl, setImageUrl] = useState(stuff.imageUrl)
  const [videoUrl, setVideoUrl] = useState(stuff.videoUrl)
  const [description, setDescription] = useState(stuff.description)

  const networks = Array.from(IdNetworkInfo.values())
  const currentNetwork = networks.find(n => n.id === chainId)
  const network = chain ? networks.find(n => n.displayName.toLowerCase() === chain.toLowerCase()) : currentNetwork

  return (
    <>
      <div className={rowClass}>
        <div> * What are you going to trade: (short description or just stuff name) </div>
        <Input _default={title} onChange={value => setTitle(value)} />
      </div>
      <div className={rowClass}>
        <div> * Want: </div>
        <Select options={['Buy', 'Sell']} _default={stuffType ? stuffType : 'Buy'} onChange={setStuffType} />
      </div>
      <div className={rowClass}>
        <div> * Locking Chain: </div>
        <NetworkSelector _default={network || EthereumNetwork} onChange={setChain} />
      </div>
      <div className={rowClass}>
        <div> * Price: </div>
        <div style={{ display: 'flex', position: 'relative' }}>
          <Input _default={price} type='number' onChange={value => setPrice(value)} />
          <CryptoSelector
            style={{ position: 'absolute', right: 0 }}
            network={network}
            _default={stuff.coin}
            onChange={(coin) => { setCoin(coin.symbol) }}
          />
        </div>
      </div>
      <div className={rowClass}>
        <div> * Locking: </div>
        <Input _default={lockAmount} type='number' onChange={value => setLockAmount(value)} />
      </div>
      <div className={rowClass}>
        <div> Image URL <small> (split by ,) </small>: </div>
        <Input _default={imageUrl} onChange={value => setImageUrl(value)} />
      </div>
      <div className={rowClass}>
        <div> Video URL: </div>
        <Input _default={videoUrl} onChange={value => setVideoUrl(value)} />
      </div>
      <div className={rowClass}>
        <div> * More detail about your stuff (support Markdown): </div>
        <Input _default={description} cols={32} rows={6} onChange={value => setDescription(value)} />
      </div>
      {
        status === 'connected' ? (
          <Button type='w160' onClick={async () => {
            await onSave({
              title, stuffType, price, coin, chain, lockAmount, imageUrl, videoUrl, description,
            })
          }}>
            { buttonText }
          </Button>
        ) : (
          <ConnectWallet />
        )
      }
    </>
  )
}

export default StuffFrom
