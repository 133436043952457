import React, { useState, useEffect, useRef } from 'react'
import { useMetaMask } from 'metamask-react'
import { useOnClickOutside } from 'usehooks-ts'

import './CryptoSelector.css'

import { Token, Network } from '../../types'
import { fetchData } from '../../fetch'
import { IdNetworkInfo, EthereumNetwork } from '../../constant'
import { networkTokenList } from '../../tokens'

import Button from '../Button'
import Card from '../Card'
import CryptoLogo from '../CryptoLogo'

import { hasElemnt } from '../../utils'

interface ProviderProps {
  onChange: (value:Token) => void
  _default: string
  network?: Network | null
  style?: object
}

function CryptoSelector({ onChange = (value: any) => {}, _default = '', network, style }:ProviderProps) {
  const { chainId } = useMetaMask()
  const _tokens = networkTokenList.get(chainId || '') || []
  const _defaultToken = _tokens.find(token => token.symbol.toLowerCase() === _default.toLowerCase())
  const [ _open, setOpen ] = useState(false)
  const [ _selected, setSelected ] = useState(_defaultToken || _tokens[0])
  const el = useRef<HTMLDivElement>(null)

  const handleClickOutside = () => {
    setOpen(false)
  }

  useOnClickOutside(el, handleClickOutside)

  useEffect(() => {
    const defaultToken = _tokens.find(token => token.symbol.toLowerCase() === _default.toLowerCase())
    const _token = defaultToken || _tokens[0]
    if (!_token) return
    setSelected(_token)
    onChange(_token)
  }, [_default, network, chainId])

  return (
    <div className='CryptoSelector' ref={el} style={style}>
      <Button noPending onClick={() => { setOpen(!_open) }}>
        <div className='CryptoSelector-button'>
          <CryptoLogo token={_selected} />
          <span className='CryptoSelector-button-text'>
            { _selected ? _selected.symbol : '' }
          </span>
        </div>
      </Button>
      <ul className='CryptoSelector-selector' style={_open ? { } : { display: 'none' }}>
        {
          _tokens.map(token => (
            <li className='CryptoSelector-selector-item' key={token.address} onClick={() => {
              setSelected(token)
              onChange(token)
              setOpen(false)
            }}>
              <CryptoLogo token={token} />
              <span className='CryptoSelector-selector-text'>{token.symbol}</span>
            </li>
          ))
        }
      </ul>
    </div>
  )
}

export default CryptoSelector
