import React, { useState, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import rightArrow from '../../assets/right-arrow.svg'

import './DealIdInput.css'

function DealIdInput() {
  const [_focus, setFocus] = useState(false)
  const _input = useRef<HTMLInputElement>(null)
  const navigate = useNavigate()

  return (
    <div className="DealId-input-container">
      {
        _focus || (_input.current && _input.current.value) ? (
          null
        ) : (
          <span className="DealId-input-placeholder" onClick={() => {
            if (_input && _input.current) {
              _input.current.focus()
            }
          }}>
            Search Deal ID
          </span>
        )
      }
      <input
        className='DealId-input number'
        ref={_input}
        onFocus={() => { setFocus(true) }}
        onBlur={() => { setFocus(false) }}
        onKeyPress={(event:React.KeyboardEvent<HTMLInputElement>) => {
          if (!_input || !_input.current) {
            return
          }
          // if event.key is not number or enter, prevent default
          if (event.key !== 'Enter' && isNaN(Number(event.key)) ) {
            event.preventDefault()
            return
          }
          // if event.key is enter and input is not empty, navigate to lock page
          if (event.key === 'Enter' && _input.current.value) {
            navigate(`/lock/${_input.current.value}`)
          }
        }}
      />
      <img className={`DealId-arrow ${_focus || (_input.current && _input.current.value)
        ? 'DealId-arrow-show' : ''}`} src={rightArrow} alt="arrow"
        onClick={(event) => {
          event.preventDefault()
          if (_input && _input.current && _input.current.value) {
            navigate(`/lock/${_input.current.value}`)
          }
        }}/>
    </div>
  )
}

export default DealIdInput
