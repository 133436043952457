import { createSlice } from '@reduxjs/toolkit'
import { Stuff, Token } from '../../types'

export interface CreateState {
  readonly amountToLock: number | string,
  readonly amountToPay: number | string,
  readonly isPayer: boolean,
  readonly fee: number | string,
  readonly feeToUsd: number | string,
  readonly tokenToLock: string,
  readonly token: Token | null,
}

const initialState: CreateState = {
  amountToLock: 0,
  amountToPay: 0,
  isPayer: false,
  fee: 0,
  feeToUsd: 0,
  tokenToLock: "",
  token: null,
}

const _createSlice = createSlice({
  name: 'create',
  initialState,
  reducers: {
    updateAmountToLock(state, action) {
      const { amountToLock } = action.payload
      state.amountToLock = Number(amountToLock)
    },
    updateAmountToPay(state, action) {
      const { amountToPay } = action.payload
      state.amountToPay = Number(amountToPay)
    },
    updateIsPayer(state, action) {
      const { isPayer } = action.payload
      state.isPayer = isPayer
    },
    updateFee(state, action) {
      const { fee } = action.payload
      state.fee = fee
    },
    updateFeeToUsd(state, action) {
      const { feeToUsd } = action.payload
      state.feeToUsd = feeToUsd
    },
    updateTokenToLock(state, action) {
      const { tokenToLock } = action.payload
      state.tokenToLock = tokenToLock
    },
    updateToken(state, action) {
      const { token } = action.payload
      state.token =token
    },
  }
})

export const {
  updateAmountToLock,
  updateAmountToPay,
  updateIsPayer,
  updateFee,
  updateFeeToUsd,
  updateTokenToLock,
  updateToken,
} = _createSlice.actions

export default _createSlice.reducer
