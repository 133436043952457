import React, { useState } from 'react'

import './ImageViewer.css'

interface Props {
  videos?: string[]
  images?: string[]
}

function ImageViewer({ videos = [], images = [] }:Props) {
  const [_isVideo, setIsVideo] = useState(false)
  const [_selected, setSelected] = useState(images.length ? images[0] : '')

  return (
    <div className='ImageViewer'>
      {
        _selected ? (
          <>
            <div className='ImageViewer-window-video' style={ _isVideo ? {} : { display: 'none' }}>
              <video src={_selected} />
            </div>
            <div className='ImageViewer-background ImageViewer-window-image'
              style={ _isVideo ? { display: 'none' } : {
                backgroundImage: `url(${_selected})`
              }}
            />
          </>
        ) : null
      }
      <div>
        {
          (images.length > 1) || (videos.length > 1) ? (
            <ul className='ImageViewer-thumb'>
              {
                videos.filter(v => !!v).map((video, index) =>
                                            (<li key={index}><video src={video} /></li>))
              }
              {
                images.map((img, index) =>
                           (<li key={index + videos.length}>
                             <div className={`ImageViewer-background ImageViewer-thumb-img ${_selected === img ? 'selected' : ''}`}
                               style={{ backgroundImage: `url(${img})` }}
                               onClick={() => {
                                 setSelected(img)
                               }}
                             />
                           </li>))
              }
            </ul>
          ) : null
        }
      </div>
    </div>
  )
}

export default ImageViewer
