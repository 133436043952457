import React, { useState, useEffect } from 'react'
import { useNavigate, Link } from 'react-router-dom'
import { useMetaMask } from 'metamask-react'
import { ethers } from 'ethers'
import useFetch from 'react-fetch-hook'
import { useEventListener, useCounter, useIsFirstRender } from 'usehooks-ts'
import qs from 'query-string'
import debounce from 'lodash.debounce'

import { useAppSelector, useAppDispatch } from '../../state'
import { fetchData } from '../../fetch'
import {
  updatePage,
  updateIsEnd,
  appendStuffs
} from '../../state/marketplace/reducer'

import './Marketplace.css'

import Card from '../../components/Card'
import Button from '../../components/Button'
import Input from '../../components/Input'
import api from '../../api'
import { Stuff } from '../../types'
import noImage from '../../assets/no-image.jpeg'

interface StuffResponse {
  count: number,
  next: string,
  previous: string,
  results: Stuff[],
}

function Marketplace() {
  const [error, setError] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const dispatch = useAppDispatch()
  const stuffs = useAppSelector(state => state.marketplace.stuffs) || []
  const isEnd = useAppSelector(state => state.marketplace.isEnd)
  const page = useAppSelector(state => state.marketplace.page)
  const pageSize = useAppSelector(state => state.marketplace.pageSize)

  const loadData = async (page:number, pageSize:number) => {
    try {
      setIsLoading(true)
      await fetchData(`${api.FETCH_STUFF}?${qs.stringify({page, pageSize})}`,
                      (data:StuffResponse) => {
                        dispatch(appendStuffs({ stuffs: data.results }))
                        if (data.next) {
                          dispatch(updatePage({ page: page + 1 }))
                        } else {
                          dispatch(updateIsEnd({ isEnd: true }))
                        }
                      })
    } catch(err) {
    } finally {
      setIsLoading(false)
    }
  }

  const onScroll = async (event: Event) => {
    if (isEnd) return
    if (isLoading) return
    if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
      await loadData(page, pageSize)
    }
  }

  useEventListener('scroll', debounce(onScroll, 50))

  useEffect(() => {
    if (stuffs.length === 0) {
      loadData(page, pageSize).catch(console.error)
    }
  }, [])

  return (
    <div className='Marketplace'>
      <div className=''>
        {
          // <div classname='marketplace-searchbar'> <input /> </div>
        }
      </div>
      <div className='Marketplace-stuffs'>
        {
          stuffs.map(stuff => {
            const images = stuff.imageUrl ? stuff.imageUrl.split(',') : []

            return (
              <Card key={stuff.id} style={{ margin: '8px auto', padding: 0, height: 320, overflow: 'hidden' }}>
                <Link to={`/detail/${stuff.id}`}>
                  {
                    images[0] ? (
                      <img className='Marketplace-card-img' src={images[0]} />
                    ) : (
                      <div className="Marketplace-card-no-img"> No Image </div>
                    )
                  }
                  <div className='Marketplace-card-img-cover' />
                </Link>
                <div className='Marketplace-card-text' style={{ marginTop: 'auto', padding: '8px 16px' }}>
                  <Link className='Marketplace-card-link' to={`/detail/${stuff.id}`}>
                    <h3> {stuff.title} </h3>
                  </Link>
                  <div>
                    <h4>
                      <b style={{ textTransform: 'uppercase' }}> {stuff.stuffType ? `Want ${stuff.stuffType}` : ''} </b>
                      @
                      <span style={{ textTransform: 'capitalize' }}> {stuff.chain} </span>
                    </h4>
                  </div>
                  <div>
                    <h5>
                      locking {stuff.lockAmount} & payment {stuff.price}
                      <span style={{ textTransform: 'uppercase' }}> {stuff.coin} </span>
                    </h5>
                  </div>
                </div>
              </Card>
            )
          })
        }
      </div>
      {
        isLoading ? <div> loading... </div> : null
      }
      {
        isEnd ? <div style={{ opacity: 0.6 }}> No more </div> : null
      }
    </div>
  )
}

export default Marketplace
