import React, { useState, useEffect, useRef, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { useMetaMask } from 'metamask-react'
import { ethers } from 'ethers'
import { useAppSelector, useAppDispatch } from '../../state'
import {
  updateAmountToLock,
  updateAmountToPay,
  updateIsPayer,
  updateFee,
  updateFeeToUsd,
  updateTokenToLock,
  updateToken,
} from '../../state/create/reducer'
import {
  networkContracts,
  networkCoins,
  coinNames,
  networks,
  IdNetworkInfo,
  EthereumNetwork,
} from '../../constant'
import { formatBalance } from '../../utils'
import { networkTokenList } from '../../tokens'
import { Token } from '../../types'
import crydealAbi from '../../assets/abi/Crydeal.json'
import erc20Abi from '../../assets/abi/IERC20.json'
import rightArrow from '../../assets/right-arrow.svg'
import ConnectWallet from '../../components/ConnectWallet'
import Card from '../../components/Card'
import Input from '../../components/Input'
import Checkbox from '../../components/Checkbox'
import Button from '../../components/Button'
import CryptoSelector from '../../components/CryptoSelector'
import DealIdInput from '../../components/DealIdInput'
import NetworkMark from '../../components/NetworkMark/NetworkMark'

import './Create.css'

function Create() {
  const { chainId, account, ethereum, status } = useMetaMask()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const fee = useAppSelector(state => state.create.fee)
  const feeToUsd = useAppSelector(state => state.create.feeToUsd)
  const amountToLock = useAppSelector(state => state.create.amountToLock)
  const amountToPay = useAppSelector(state => state.create.amountToPay)
  const isPayer = useAppSelector(state => state.create.isPayer)
  const token = useAppSelector(state => state.create.token)

  const [_focus, setFocus] = useState(false)
  const [_isApprove, setIsApprove] = useState(false)
  const [_errText, setError] = useState('')
  const [_balance, setBalance] = useState(ethers.BigNumber.from(0))
  const [_decimals, setDecimal] = useState('18')

  const CRYDEAL = chainId ? networkContracts.get(chainId) || '' : ''
  const _tokens = chainId ? networkTokenList.get(chainId) : []
  const _defaultToken = _tokens && _tokens.length > 0 ? _tokens[0] : null

  const getTokenData = async () => {
    try {
      if (!token) return
      if (!account) return
      const provider = new ethers.providers.Web3Provider(ethereum)
      const signer = provider.getSigner(account)
      const tokenContract = new ethers.Contract(token.address, erc20Abi, signer)
      const [_deci, _bala] = await Promise.all([
        tokenContract.decimals(),
        tokenContract.balanceOf(account)
      ])
      setDecimal(_deci)
      setBalance(_bala)
    } catch(err) {
      console.error(err)
    }
  }

  useEffect(() => {
    if (!token) {
      dispatch(updateToken({ token: _defaultToken }))
    }
    if (status === 'connected') {
      getTokenData().catch(console.error)
    }
  }, [])

  useEffect(() => {
    if (status === 'connected' && token) {
      checkApprove(token.address).catch(console.error)
    }
  }, [status, account, token])

  useEffect(() => {
    getTokenData().catch(console.error)
  }, [account, token])

  useEffect(() => {
    dispatch(updateToken({ token: _defaultToken }))
  }, [chainId])

  const accountBalance = useMemo(
    () => formatBalance(ethers.utils.formatUnits(_balance, _decimals)),
    [_balance])

  const checkApprove = async (tokenAddress:string) => {
    if (!account) return
    if (!tokenAddress) return
    try {
      const provider = new ethers.providers.Web3Provider(ethereum)
      const signer = provider.getSigner(account)
      const tokenContract = new ethers.Contract(tokenAddress, erc20Abi, signer)
      const allowance = await tokenContract.allowance(account, CRYDEAL)
      if (allowance.isZero()) {
        setIsApprove(false)
      } else {
        setIsApprove(true)
      }
    } catch(err) {
      console.error('Check approve error', err)
      setIsApprove(false)
    }
  }

  const ConfirmButton = () => {
    if (status !== 'connected' || !account) {
      return <ConnectWallet />
    }

    if (!token) {
      return (
        <Button type='w210' disabled>
          Please select a token
        </Button>
      )
    }

    if (amountToPay > amountToLock) {
      return (
        <Button type='large' disabled>
          Insufficient payment
        </Button>
      )
    }

    if (_balance.eq(0) || _balance.lt(ethers.utils.parseEther(amountToLock.toString()))) {
      return (
        <Button type='large' disabled>
          Insufficient balance
        </Button>
      )
    }

    const provider = new ethers.providers.Web3Provider(ethereum)
    const signer = provider.getSigner(account)
    const coinContract = new ethers.Contract(token.address, erc20Abi, signer)
    const crydealContract = new ethers.Contract(CRYDEAL, crydealAbi, signer)

    if (!_isApprove) {
      return (
        <Button
          type='w160'
          disabled={Number(amountToLock) === 0}
          onClick={async () => {
            try {
              if (!token) return
              if (amountToLock === 0) return
              // const tx = await coinContract.approve(CRYDEAL, '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff')
              const tx = await coinContract.approve(CRYDEAL, amountToLock)
              await tx.wait()
              await checkApprove(token.address)
            } catch (err) {
              console.error(err)
            }
          }}>
          Approve
        </Button>
      )
    }

    return (
      <Button type='w160'
        onClick={async () => {
          try {
            if (!token) {
              return
            }
            if (!amountToLock) {
              return
            }
            if (!amountToPay && amountToPay !== 0) {
              return
            }

            const provider = new ethers.providers.Web3Provider(ethereum)
            const signer = provider.getSigner(account)
            const tx = await crydealContract.create(
              ethers.utils.parseUnits(amountToLock.toString(), _decimals),
              ethers.utils.parseUnits(amountToPay.toString(), _decimals),
              !isPayer, token.address)
            const res = await tx.wait()

            if (res.status === 1) {
              console.log(res)
              for (let i = 0; i < res.events.length; i += 1) {
                const event = res.events[i]
                if (event.event && event.event === 'Create') {
                  const nextLockId = event.args[3].toNumber()
                  setTimeout(() => {
                    navigate(`/lock/${nextLockId}`)
                  }, 0)
                  break
                }
              }
            }
          } catch (err) {
            console.error(err)
          }
        }}>
        Confirm
      </Button>
    )
  }

  return (
    <div className='Create'>
      <Card>
        <div className='Create-dealId-input'>
          <DealIdInput />
        </div>
        <div className='Card-title'> Make a Deal </div>
        <NetworkMark style={{ marginTop: '-16px', fontSize: 12 }} link={false} />
        <div className='Create-row'>
          <div className='Card-label'>Token</div>
          <div className='Create-input-row'>
            {
              chainId ? (
                <CryptoSelector
                  _default={_defaultToken ? _defaultToken.name : ''}
                  onChange={async (token) => {
                    dispatch(updateToken({ token }))
                    await checkApprove(token.address)
                  }}
                />
              ) : null
            }
            <div style={{ position: 'relative' }}>
              <div className='number'> {accountBalance} </div>
              {
                // <small style={{ position: 'absolute', fontSize: 10, left: 0 }}>
                //   balance
                // </small>
              }
            </div>
          </div>
        </div>
        <div className='Create-row'>
          <div className='Card-label'>Lock</div>
          <div className='Create-input-row'>
            <Input
              type='number'
              onChange={value => {
                dispatch(updateAmountToLock({
                  amountToLock: value
                }))
              }}
              _default={amountToLock}
            />
          </div>
        </div>
        <div className='Create-row'>
          <div className='Card-label'>Payment</div>
          <div className='Create-input-row'>
            <Input
              type='number'
              onChange={value => {
                dispatch(updateAmountToPay({
                  amountToPay: value
                }))
              }}
              _default={amountToPay}
            />
          </div>
        </div>
        <div className='Create-row-flex-row'>
          <div className='Card-label inline'>I Pay</div>
          <Checkbox
            onChange={value => {
              dispatch(updateIsPayer({
                isPayer: value
              }))
            }}
          />
        </div>
        <div style={{ marginTop: 16 }}>
          <ConfirmButton />
        </div>
        <div className='Create-help'>
            Establish a one-to-one lock-up agreement with your trading partner.
          <br/>
            Both of you will lock an equal amount of tokens,
            which will be locked until both parties confirm that the deal has been successfully completed.
          <br/>
            The tokens cannot be released until both parties agree
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
            If you specify a payment, and you are payer, you should click [I Pay].
          <br/>
            The payment will be deducted from the lock amount, and cannot be greater than the lock amount.
          <br/>
          <br/>
        </div>
      </Card>
    </div>
  )
}

export default Create
