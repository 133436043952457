import React from 'react'
import { useMetaMask } from 'metamask-react'
import { IdNetworkInfo } from '../../constant'

interface Props{
  style?:object
  link?: boolean
}

function NetworkMark({ style, link = true }:Props) {
  const { chainId } = useMetaMask()
  const network = IdNetworkInfo.get(chainId || '')

  if (!network) return null

  return (
    <div style={{ textTransform: 'capitalize', ...style }}>
      {
        link ? (
          <a style={{ textDecoration: 'none' }} href={network.website} target='_blank' rel='noopener noreferrer'>
            @{network.displayName}
          </a>
        ) : (
          <span>
            @{network.displayName}
          </span>
        )
      }
    </div>
  )
}

export default NetworkMark
