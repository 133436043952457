import React, { useState } from 'react'

import './Input.css'

interface ProviderProps {
  onChange?: (value:any) => void,
  type?: string,
  _default?: any,
  cols?: number,
  rows?: number,
}

const pattern = /^\d+(\.\d+)*$/

function Input({ onChange = (value:any) => {}, type = 'text', _default = '', cols, rows}: ProviderProps) {
  const [value, setValue] = useState(_default || '')

  const _numberProcess = (event:React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => {
    let _value
    if (!event.target.value) {
      _value = ''
    } else if (event.target.value === '.') {
      _value = '0.'
    } else if (event.target.value.endsWith('.') && !event.target.value.slice(0, event.target.value.length - 1).includes('.')) {
      _value = event.target.value
    } else if (event.target.value.startsWith('0') && event.target.value.length > 1 && !event.target.value.startsWith('0.')) {
      _value = event.target.value.slice(1)
    } else if (pattern.test(event.target.value)) {
      _value = event.target.value
    } else {
      event.preventDefault()
      return
    }

    setValue(_value)
    onChange(_value)
  }

  const _onChange = (event:React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => {
    if (type === 'number') {
      return _numberProcess(event)
    } else {
      setValue(event.target.value)
      onChange(event.target.value)
    }
  }

  if (cols && rows) {
    return (
      <textarea onChange={_onChange} value={value} className='Input textarea' cols={cols} rows={rows} />
    )
  }

  return (
    <input
      onChange={_onChange}
      value={value}
      className={`Input ${type}`}
      type='text'
    />
  )
}

export default Input
