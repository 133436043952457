import React, { useState, useEffect, useRef } from "react"
import { useOnClickOutside } from 'usehooks-ts'
import { useMetaMask } from 'metamask-react'
import CopyToClipboard from "react-copy-to-clipboard"
import { ethers } from 'ethers'

import { IdNetworkInfo } from '../../constant'
import etherscanLogo from '../../assets/etherscan-logo-light-circle.svg'
import chatLogo from '../../assets/chat.svg'
import copyLogo from '../../assets/copy.svg'

import './Address.css'

interface ProviderProps {
  address?: string,
  count?: number
}

const shorthandAddress = (address:string, count:number = 4) => {
  let start
  if (!address.startsWith('0x')) {
    address = `0x${address}`
  }
  if (count === 0) {
    return address
  }
  return `${address.substring(0, count + 2)}...${address.substring(
    address.length - count, address.length)}`
}

function Address({ address = '', count = 6 } : ProviderProps) {
  const [_open, setOpen] = useState(false)
  const [_copied, setCopied] = useState(false)
  const ref = useRef<HTMLDivElement>(null)
  const { chainId } =  useMetaMask()
  const _network = IdNetworkInfo.get(chainId || '')
  const _exploreLink = _network ? `${_network.explorer}/address/${address}` : '#'
  const _address = ethers.utils.getAddress(address)

  const handleClickOutside = () => setOpen(false)
  const handleClickInside = () => setOpen(true)

  useOnClickOutside(ref, handleClickOutside)

  return (
    <div className="Address" ref={ref} onClick={handleClickInside} onMouseLeave={() => { setCopied(false) }}>
      <span className="Address-text"> {shorthandAddress(_address, count)} </span>
      {
        _open ? (
          <div className="Address-panel">
            <a className="Address-link" href={_exploreLink} target="_blank">
              <img className="Address-img"src={etherscanLogo} alt="Etherscan" />
              <span className="Address-text"> Etherscan </span>
            </a>
            <a className="Address-link" href={`https://chat.blockscan.com/index?a=${_address}`} target="_blank">
              <img className="Address-img"src={chatLogo} alt="Blockchat" />
              <span className="Address-text"> Chat </span>
            </a>
            <CopyToClipboard text={_address} onCopy={() => { setCopied(true) }}>
              <a className="Address-link">
                <img className="Address-img"src={copyLogo} alt="Copy" />
                <span className="Address-text"> { _copied ? "Copied" : "Copy" } </span>
              </a>
            </CopyToClipboard>
          </div>
        ) : null
      }
    </div>
  )
}

export default Address
