import { Token } from './types'
import { EthereumNetwork, ArbitrumNetwork, OptimismNetwork, BlastNetwork } from './constant'

export const networkTokenList:Map<string, Token[]> = new Map([
  [EthereumNetwork.id, [
    {
      "address": "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2",
      "name": "WETH",
      "symbol": "WETH",
      "decimals": 18,
      "logoURI": "https://assets-cdn.trustwallet.com/blockchains/ethereum/assets/0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2/logo.png",
    },
    {
      "address": "0xdAC17F958D2ee523a2206206994597C13D831ec7",
      "name": "Tether",
      "symbol": "USDT",
      "decimals": 6,
      "logoURI": "https://assets-cdn.trustwallet.com/blockchains/ethereum/assets/0xdAC17F958D2ee523a2206206994597C13D831ec7/logo.png",
    },
    {
      "address": "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48",
      "name": "USD Coin",
      "symbol": "USDC",
      "decimals": 6,
      "logoURI": "https://assets-cdn.trustwallet.com/blockchains/ethereum/assets/0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48/logo.png",
    },
    {
      "address": "0x6B175474E89094C44Da98b954EedeAC495271d0F",
      "name": "Dai",
      "symbol": "DAI",
      "decimals": 18,
      "logoURI": "https://assets-cdn.trustwallet.com/blockchains/ethereum/assets/0x6B175474E89094C44Da98b954EedeAC495271d0F/logo.png",
    },
  ]],

  [ArbitrumNetwork.id, [
    {
      "address": "0x82aF49447D8a07e3bd95BD0d56f35241523fBab1",
      "name": "Wrapped Ether",
      "symbol": "WETH",
      "decimals": 18,
      "logoURI": "https://assets-cdn.trustwallet.com/blockchains/arbitrum/assets/0x82aF49447D8a07e3bd95BD0d56f35241523fBab1/logo.png",
    },
    {
      "address": "0xFF970A61A04b1cA14834A43f5dE4533eBDDB5CC8",
      "name": "USD Coin (Arb1)",
      "symbol": "USDC",
      "decimals": 6,
      "logoURI": "https://assets-cdn.trustwallet.com/blockchains/arbitrum/assets/0xFF970A61A04b1cA14834A43f5dE4533eBDDB5CC8/logo.png",
    },
    {
      "address": "0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9",
      "name": "Tether USD",
      "symbol": "USDT",
      "decimals": 6,
      "logoURI": "https://assets-cdn.trustwallet.com/blockchains/arbitrum/assets/0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9/logo.png",
    },
  ]],

  [OptimismNetwork.id, [
    {
      "address": "0x4200000000000000000000000000000000000006",
      "name": "Wrapped Ether",
      "symbol": "WETH",
      "decimals": 18,
      "logoURI": "https://assets-cdn.trustwallet.com/blockchains/optimism/assets/0x4200000000000000000000000000000000000006/logo.png",
    },
  ]],

  [BlastNetwork.id, [
    {
      "address": "0x4300000000000000000000000000000000000004",
      "name": "Wrapped Ether",
      "symbol": "WETH",
      "decimals": 18,
      "logoURI": "https://blastscan.io/token/images/weth_32.png",
    },
    {
      "address": "0x4300000000000000000000000000000000000003",
      "name": "USDB",
      "symbol": "USDB",
      "decimals": 18,
      "logoURI": "https://blastscan.io/token/images/usdb_32.png",
    },
  ]]
])
