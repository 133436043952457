import React, { useState } from 'react'
import { Network } from '../../types'
import { IdNetworkInfo } from '../../constant'
import Select from '../../components/Select'

import './NetworkSelector.css'

interface Props {
  onChange?:(value:any) => void
  _default?:Network
}

function NetworkSelector({ onChange=()=>{}, _default }:Props) {
  const networks = Array.from(IdNetworkInfo.values())

  return (
    <Select
      _default={_default ? _default.displayName : ''}
      options={networks.map(n => n.displayName)}
      onChange={onChange}
    />
  )
}

export default NetworkSelector
