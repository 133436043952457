import { Network } from './types'

export const ZERO_ADDRESS = '0x0000000000000000000000000000000000000000'

export const EthereumNetwork = {
  'id': '0x1',
  'name': 'Ethereum',
  'displayName': 'Ethereum',
  'website': 'https://ethereum.org/',
  'explorer': 'https://etherscan.io/',
  'logoURI': 'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/info/logo.png',
  'dailyBlock': 7 * 1000,
}

export const ArbitrumNetwork = {
  'id': '0xa4b1',
  'name': 'Arbitrum One',
  'displayName': 'Arbitrum',
  'website': 'https://offchainlabs.com',
  'explorer': 'https://arbiscan.io',
  'logoURI': 'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/arbitrum/info/logo.png',
  'dailyBlock': 330 * 1000,
}

export const OptimismNetwork = {
  'id': '0xa',
  'name': 'Optimism',
  'displayName': 'Optimism',
  'website': 'https://optimism.io/',
  'explorer': 'https://optimistic.etherscan.io',
  'logoURI': 'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/optimism/info/logo.png',
  'dailyBlock': 430 * 1000,
}

export const BlastNetwork = {
  'id': '0x13e31',
  'name': 'Blast',
  'displayName': 'Blast',
  'website': 'https://blast.io',
  'explorer': 'https://blastscan.io',
  'logoURI': 'https://assets-global.website-files.com/65a6baa1a3f8ed336f415cb4/65a6cee39aadb0fa7418aa77_Blast%20Logo%20Icon%20Yellow.svg',
  'dailyBlock': 12 * 3600,
}

export const IdNetworkInfo: Map<string, Network> = new Map([
  ['0x1', EthereumNetwork],
  ['0xa4b1', ArbitrumNetwork],
  ['0xa', OptimismNetwork],
  ['0x13e31', BlastNetwork],
])


export const networkContracts: Map<string, string> = new Map([
  // ethereum
  ['0x1',    '0x3bf5B46b0767b0f0A1ca2D5fFE5916975994DA54'],
  // ropsten
  ['0x3',    '0x373A800E1536E9c2a6D361519Dd45aAb90090dF2'],
  // goerli
  ['0x5',    '0x373A800E1536E9c2a6D361519Dd45aAb90090dF2'],
  // hardhat
  ['0x7a69', '0x5FbDB2315678afecb367f032d93F642f64180aa3'],
  // optimism
  ['0xa',    '0x7737a35B165AC1Ff1fe0E5B215F9885b9C67c2b0'],
  // arbitrum
  ['0xa4b1', '0xEBdc02713EdF14a4c1e67813AF7654d146BC67BE'],
  // blast
  ['0x13e31', '0x9Cbd3f9916aC449e95876dFd670b2bF343EfDdB7'],
])

export const networks: Map<string, string> = new Map([
  ['0x1',     'ethereum'],          // 1
  ['0x5',     'goerli'],            // 5
  ['0x3',     'ropsten'],           // 3
  ['0x4',     'rinkeby'],           // 4
  ['0x2a',    'kovan'],             // 42
  ['0x13881', 'mumbai'],            // 80001
  ['0xa4b1',  'arbitrum'],          // 42161
  ['0xa',     'optimism'],          // 10
  ['0x89',    'polygon'],           // 137
  ['0x64',    'gnosisChain'],       // 100
  ['0x38',    'binanceSmartChain'], // 56
  ['0xa86a',  'avalanche'],         // 43114
  ['0x19',    'cronos'],            // 25
  ['0xfa',    'fantom'],            // 250
  ['0x7a69',  'hardhat'],
  ['0x13e31', 'blast'],
])

export const networkWebsite = new Map([
  ['0x1',     'https://ethereum.org'],
  ['0x5',     'goerli'],
  ['0x3',     'ropsten'],
  ['0x4',     'rinkeby'],
  ['0x2a',    'kovan'],
  ['0x13881', 'mumbai'],
  ['0xa4b1',  'https://arbitrum.io/'],
  ['0xa',     'https://www.optimism.io/'],
  ['0x89',    'polygon'],
  ['0x64',    'gnosisChain'],
  ['0x38',    'binanceSmartChain'],
  ['0xa86a',  'avalanche'],
  ['0x19',    'cronos'],
  ['0xfa',    'fantom'],
  ['0x7a69',  'hardhat'],
  ['0x13e31', 'https://blast.io'],
])

export const coinNames: Map<string, string> = new Map([
  // Ethereum
  ['0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2', 'WETH'],
  ['0xdAC17F958D2ee523a2206206994597C13D831ec7', 'USDT'],
  ['0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48', 'USDC'],
  // ropsten
  ['0xc778417E063141139Fce010982780140Aa0cD5Ab', 'WETH'],
  ['0x6EE856Ae55B6E1A249f04cd3b947141bc146273c', 'USDT'],
  // Optimism
  ['0x4200000000000000000000000000000000000006', 'WETH'],
  ['0x94b008aA00579c1307B0EF2c499aD98a8ce58e58', 'USDT'],
  ['0x7F5c764cBc14f9669B88837ca1490cCa17c31607', 'USDC'],
  // Arbitrum
  ['0x82aF49447D8a07e3bd95BD0d56f35241523fBab1', 'WETH'],
  ['0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9', 'USDT'],
  ['0xFF970A61A04b1cA14834A43f5dE4533eBDDB5CC8', 'USDC'],
  // Blast
  ['0x4300000000000000000000000000000000000004', 'WETH'],
  ['0x4300000000000000000000000000000000000003', 'USDB'],
])

export const networkCoins: Map<string, Array<string>> = new Map([
  // mainnet
  [
    '0x1',
    [
      // WETH
      '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
      // USDT
      '0xdAC17F958D2ee523a2206206994597C13D831ec7',
      // USDC
      '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
    ]
  ],
  // ropsten
  [
    '0x3',
    [
      // WETH
      '0xc778417E063141139Fce010982780140Aa0cD5Ab',
      // USDT
      '0x6EE856Ae55B6E1A249f04cd3b947141bc146273c',
    ]
  ],
  // goerli
  [
    '0x5',
    [

    ]
  ],
  [
    // optimism
    '0xa',
    [
      // WETH
      '0x4200000000000000000000000000000000000006',
      // USDT
      '0x94b008aA00579c1307B0EF2c499aD98a8ce58e58',
      // USDC
      '0x7F5c764cBc14f9669B88837ca1490cCa17c31607',
    ]
  ],
  [
    // arbitrum
    '0xa4b1',
    [
      // WETH
      '0x82aF49447D8a07e3bd95BD0d56f35241523fBab1',
      // USDT
      '0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9',
      // USDC
      '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
    ]
  ],
  [
    // blast
    '0x13e31',
    [
      // WETH
      '0x4300000000000000000000000000000000000004',
      // USDB
      '0x4300000000000000000000000000000000000003',
    ]
  ]
])

export const networkRpc: Map<string, any> = new Map([
  [
    '0xa4b1',
    {
      chainId: '0xa4b1',
      chainName: 'Arbitrum One',
      nativeCurrency: {
        name: 'Arbitrum One',
        symbol: 'ETH',
        decimals: 18
      },
      blockExplorerUrls: [
        'https://arbiscan.io/'
      ],
      rpcUrls: [
        'https://arb1.arbitrum.io/rpc'
      ],
    }
  ],
  [
    '0xa',
    {
      chainId: '0xa',
      chainName: 'Optimism',
      nativeCurrency: {
        name: 'Optimism',
        symbol: 'ETH',
        decimals: 18
      },
      blockExplorerUrls: [
        'https://optimistic.etherscan.io/'
      ],
      rpcUrls: [
        'https://mainnet.optimism.io/'
      ],
    }
  ],
  [
    '0x13e31',
    {
      chainId: '0x13e31',
      chainName: 'Blast',
      nativeCurrency: {
        name: 'Blast',
        symbol: 'ETH',
        decimals: 18
      },
      blockExplorerUrls: [
        'https://blastscan.io/'
      ],
      rpcUrls: [
        'https://rpc.blast.io/'
      ],
    }
  ]
])
