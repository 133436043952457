export const fetchData = async (api:string, successCallback:Function, options?:object) => {
  const requestOptions = {
    method: 'GET',
    // mode: 'cors' as RequestMode,
    // credentials: 'include' as RequestCredentials,
    headers: { 'Content-Type': 'application/json' },
  }

  await fetch(api, requestOptions)
  .then(async response => {
    const isJson = response.headers.get('content-type')?.includes('application/json')
    const data = isJson && await response.json()

    if (!response.ok) {
      // get error message from body or default to response status
      const error = (data && data.message) || response.status
      return Promise.reject(error)
    }

    successCallback(data)
  })
  .catch(error => {
    console.error('There was an error!', error)
  })
}

export const createUpdateData = async (method:string, data:any, api:string, successCallback:Function) => {
  const requestOptions = {
    method: method,
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  }

  await fetch(api, requestOptions)
  .then(async response => {
    const isJson = response.headers.get('content-type')?.includes('application/json')
    const data = isJson && await response.json()

    if (!response.ok) {
      // get error message from body or default to response status
      const error = (data && data.message) || response.status
      return Promise.reject(error)
    }

    successCallback(data)
  })
  .catch(error => {
    console.error('There was an error!', error)
  })
}
