import { createSlice } from '@reduxjs/toolkit'
import { Stuff } from '../../types'

export interface DataState {
  readonly amountToLock: number | string,
  readonly amountToPay: number | string,
  readonly isPayer: boolean,
  readonly fee: number | string,
  readonly feeToUsd: number | string,
  readonly tokenToLock: string,
  readonly stuffs: Stuff[],
}

const initialState: DataState = {
  amountToLock: "",
  amountToPay: "",
  isPayer: false,
  fee: "",
  feeToUsd: "",
  tokenToLock: "",
  stuffs: [],
}

const dataSlice = createSlice({
  name: 'data',
  initialState,
  reducers: {
    updateAmountToLock(state, action) {
      const { amountToLock } = action.payload
      state.amountToLock = amountToLock
    },
    updateAmountToPay(state, action) {
      const { amountToPay } = action.payload
      state.amountToPay = amountToPay
    },
    updateIsPayer(state, action) {
      const { isPayer } = action.payload
      state.isPayer = isPayer
    },
    updateFee(state, action) {
      const { fee } = action.payload
      state.fee = fee
    },
    updateFeeToUsd(state, action) {
      const { feeToUsd } = action.payload
      state.feeToUsd = feeToUsd
    },
    updateTokenToLock(state, action) {
      const { tokenToLock } = action.payload
      state.tokenToLock = tokenToLock
    },
    appendStuffs(state, action) {
      const { stuffs } = action.payload
      state.stuffs = [...state.stuffs, ...stuffs]
    },
    updateStuffs(state, action) {
      const { stuffs } = action.payload
      state.stuffs = stuffs
    },
  }
})

export const {
  updateAmountToLock,
  updateAmountToPay,
  updateIsPayer,
  updateFee,
  updateFeeToUsd,
  updateTokenToLock,
  appendStuffs,
  updateStuffs,
} = dataSlice.actions

export default dataSlice.reducer
