import React, { useState, useEffect } from 'react'
import { useMetaMask } from 'metamask-react'
import { ethers } from 'ethers'
import Blockies from 'react-blockies'

import { formatBalance } from '../../utils'
import { networks } from '../../constant'

import Address from '../Address'
import Button from '../Button'
import Dialog from '../Dialog'
import Networks from '../Networks'

// https://github.com/facebook/create-react-app/issues/11770
// eslint-disable-next-line
import MetaMaskLogo from '!file-loader!../../assets/metamask-fox.svg'

import './ConnectWallet.css'

interface ProviderProps {
}

function getChainName(chainId:string | null) {
  if (chainId === "0x1" || chainId == "0x5") {
    return networks.get(chainId)
  } else {
    return (
      <div style={{ position: "relative" }}>
        <div> Wrong Network </div>
        <small style={{
          position: "absolute",
          fontSize: 10,
          left: 0,
          width: "100%",
        }}>
          ethereum only
        </small>
      </div>
    )
  }
}

function getChainBaseCoin(chainId:string | null) {
  if (chainId === '0x1') {
    return 'ETH'
  }
  if (chainId === '0x38') {
    return 'BNB'
  }
  if (chainId === '0xfa') {
    return 'FTM'
  }
  return ''
}

function cropAccount(address:string | null) {
  if (address == null) {
    return null
  }
  return `${address.substring(0, 6)}...${address.substring(address.length - 4, address.length)}`
}

// function formatBalance(balance:string) {
//   const [a, b] = balance.split('.')
//   let t = b.substring(0, 3)
//   while(t.length > 0 && t.endsWith('0')) {
//     t = t.substring(0, t.length - 1)
//   }
//   if (t) {
//     return `${a}.${t}`
//   } else {
//     return a
//   }
// }

function SelectWallet() {
  return null
}

function ConnectWallet({ }: ProviderProps) {
  const {status, connect, account, chainId, ethereum} = useMetaMask()
  const [balance, setBalance] = useState('')
  const [dialogClosed, setDialogClosed] = useState(true)

  const _openDialog = () => {
    setDialogClosed(false)
  }

  const _closeDialog = () => {
    setDialogClosed(true)
  }

  const _connectWallet = () => {
    connect()
    _closeDialog()
  }

  useEffect(() => {
    if (status === "connected" && account !== null) {
      const provider = new ethers.providers.Web3Provider(ethereum)
      provider.getBalance(account).then(res => {
        setBalance(ethers.utils.formatEther(res))
      })
    }
  })

  if (status === "initializing") return <div>Synchronisation with MetaMask ongoing...</div>

  if (status === "unavailable") return <div style={{ marginRight: 32 }}>MetaMask not available :(</div>

  if (status === "notConnected") return (
    <>
      <Button onClick={_openDialog}>
        <span style={{ padding: "0 16px" }}>Connect</span>
      </Button>
      {
        dialogClosed ? null : (
          <Dialog width={350} title="SelectWallet" onClose={() => _closeDialog()}>
            <div className="WalletItem" onClick={_connectWallet}>
              <img src={MetaMaskLogo} alt="metamask" /> MetaMask
            </div>
          </Dialog>
        )
      }
    </>
  )

  if (status === "connecting") return <div>Connecting...</div>

  if (status === "connected") {
    return (
      <div className="WalletAddress">
        <Networks />
        <span>
          {balance ? `${formatBalance(balance)} ${getChainBaseCoin(chainId)}` : '...'}
        </span>
        <div style={{ margin: '0 8px' }}>
          <Address address={account ? account : ""} count={6} />
        </div>
        <Blockies
          seed={account ? account : ''}
          size={8}
          scale={3}
          color="#eee"
          spotColor="#232931"
          bgColor="#232931"
        />
      </div>
    )
  }

  return null
}

export default ConnectWallet
