import React, { useState, useEffect } from 'react'

import './Select.css'

interface Props {
  onChange?:(value:any) => void
  options?:string[]
  _default?:string
  className?:string
}

function Select({ onChange=()=>{}, options = [], _default, className }:Props) {
  const [selected, setSelected] = useState(_default)

  useEffect(() => {
    setSelected(_default)
    onChange(_default)
  }, [_default])

  return (
    <select className={className ? className : 'Select'} value={selected}
      onChange={event => {
        onChange(event.target.value)
        setSelected(event.target.value)
      }}>
      {
        options.map(option => (
          <option key={option} value={option}>
            { option }
          </option>
        ))
      }
    </select>
  )
}

export default Select
