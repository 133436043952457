import React, { useState } from 'react'
import './Button.css'

interface ProviderProps {
  children: React.ReactNode,
  type?: string,
  onClick?: () => void,
  disabled?: boolean,
  noPending?: boolean,
  border?: string,
  style?: object,
}

function Button({ type = 'primary', border,
  children, disabled = false, onClick = () => {}, noPending = false, style }: ProviderProps) {
  const [ pending, setPending ] = useState(false)

  const _beforeClick = () => {
    setPending(true && !noPending)
  }

  const _afterClick = () => {
    setPending(false)
  }

  const _onClick = async () => {
    _beforeClick()
    await onClick()
    _afterClick()
  }

  return (
    <button
      disabled={disabled || pending}
      onClick={_onClick}
      style={border ? { border: `${border}`, ...style } : style}
      className={`Button ${type.split(' ').map(t => `Button-${t}`).join(' ')} ${disabled || pending ? 'Button-disabeld' : ''}`}>
      { pending ? '...' : children }
    </button>
  )
}

export default Button
