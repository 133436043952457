import React, { useState, useEffect } from 'react'
import { useMetaMask } from 'metamask-react'
import { useParams } from 'react-router-dom'
import useFetch from 'react-fetch-hook'
import createTrigger from 'react-use-trigger'
import useTrigger from 'react-use-trigger/useTrigger'
import ReactMarkdown from 'react-markdown'
import api from '../../api'
import { Stuff } from '../../types'
import { signAccount } from '../../utils'
import { createUpdateData } from '../../fetch'

import Card from '../../components/Card'
import Button from '../../components/Button'
import Address from '../../components/Address'
import ImageViewer from '../../components/ImageViewer'
import StuffForm from '../../components/StuffForm'

import './Detail.css'

const requestTrigger = createTrigger()

function Detail() {
  const { id } = useParams()
  const { account, ethereum } = useMetaMask()
  const [currentImageIndex, setCurrentIndex] = useState(0);
  const [openManagement, setOpenManagement] = useState(false);
  const [d, setDetail] = useState<Stuff>()
  const { isLoading, data, error } = useFetch(api.FETCH_STUFF_DETAIL(id))

  useEffect(() => {
    setDetail(data as Stuff)
  }, [data])


  if (isLoading || !d) {
    return (
      <div>loading...</div>
    )
  }

  if (error) {
    return (
      <div>{error}</div>
    )
  }

  const images = d.imageUrl ? d.imageUrl.split(',') : []
  const videos = d.videoUrl ? d.videoUrl : ''

  const gotoPrevious = () =>
    currentImageIndex > 0 && setCurrentIndex(currentImageIndex - 1)

  const gotoNext = () =>
    currentImageIndex + 1 < images.length &&
    setCurrentIndex(currentImageIndex + 1)

  return (
    <div className='Detail'>
      {
        openManagement ? (
          <Card classes="Card-wide">
            <StuffForm rowClass='Detail-edit-row' stuff={d}
              onSave={async (data:object) => {
                const sign = await signAccount(account, ethereum)
                await createUpdateData('PUT', {
                  ...data,
                  account,
                  sign,
                  ownerAddress: account
                }, api.FETCH_STUFF_DETAIL(id), (_data:any) => {
                  setDetail(_data as Stuff)
                })
              }}
            />
          </Card>
        ) : (
          <>
            <div className='Detail-row'>
              <ImageViewer videos={[videos]}  images={images} />
            </div>
            <div className='Detail-row'>
              <span style={{ marginRight: 24 }}> { d.closed ? 'Closed' : 'Open' } </span>
              {
                d.ownerAddress === account ? (
                  <Button type='w120' onClick={async () => {
                    const sign = await signAccount(account, ethereum)
                    await createUpdateData('PATCH', {
                      closed: !d.closed,
                      account,
                      sign,
                    }, api.FETCH_STUFF_DETAIL(id), (_data:any) => {
                      setDetail(_data as Stuff)
                    })
                  }}>
                    {
                      d.closed ? 'Reopen' : 'Close'
                    }
                  </Button>
                ) : null
              }
            </div>
            <div className='Detail-row'> {d.title} </div>
            <div className='Detail-row'> Want: {d.stuffType} </div>
            <div className='Detail-row'> Price: {d.price} {d.coin} </div>
            <div className='Detail-row'> Lock:  {d.lockAmount} {d.coin} </div>
            <div className='Detail-row'>
              Owner: <Address address={d.ownerAddress || ''} count={0} />
            </div>
            <div className='Detail-row'>
              <a href={`https://chat.blockscan.com/index?a=${d.ownerAddress}`} target='_blank' rel="noreferrer">
                Chat on Blockscan Chat
              </a>
            </div>
            <div className='Detail-row'> Expected Locking Chain: {d.chain} </div>
            <div className='Detail-row'>
              <div style={{ textAlign: 'left' }}>
                <ReactMarkdown children={d.description} />
              </div>
            </div>
          </>
        )
      }
      <div className='Detail-row'>
        {
          d.ownerAddress === account ? (
            <Button
              type='w210'
              onClick={() => { setOpenManagement(!openManagement) }}
              style={{ marginTop: 64 }}>
                { openManagement ? 'Close Management' : 'Open Management' }
            </Button>
          ) : null
        }
      </div>
    </div>
  )
}

export default Detail



